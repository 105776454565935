/* eslint-disable import/prefer-default-export */
import { UpdateVisitorGroup, UpdateVisitorGroup_updateVisitorGroup } from 'graphql/generated/UpdateVisitorGroup';
import { createMockedMobileDevice } from 'mocks/objectsBaseOnSchemaType/mobileDevice';
import { createMockedVisitorGroupWithMembersAndRoleAndMaxNumberOfUsersCoContractors } from 'mocks/objectsBaseOnSchemaType/visitorGroupWithMembersAndRoleAndMaxNumberOfUsersCoContractors';
import { createMockedVisitorWithVisitorGroupMembership } from 'mocks/objectsBaseOnSchemaType/visitorWithVisitorGroupMembership';

export function mockedUpdateVisitorGroupData(
  updateVisitorGroup: UpdateVisitorGroup_updateVisitorGroup
): UpdateVisitorGroup {
  return {
    updateVisitorGroup
  };
}

export const mockedUpdateVisitorGroupDataForMutation = mockedUpdateVisitorGroupData(
  createMockedVisitorGroupWithMembersAndRoleAndMaxNumberOfUsersCoContractors({
    id: 'mockedVisitorGroupIdCoContractors1',
    members: [
      createMockedVisitorWithVisitorGroupMembership({
        id: 'mockedVisitorIdCoContractor1',
        name: 'mockedVisitorNameCoContractor1',
        email: 'mockedVisitorEmailCoContractor1'
      }),
      createMockedVisitorWithVisitorGroupMembership({
        id: 'mockedVisitorIdCoContractor2',
        name: 'mockedVisitorNameCoContractor2',
        email: 'mockedVisitorEmailCoContractor2',
        mobileDevices: [createMockedMobileDevice({ id: 'mockedMobileDevice', isRegistered: true })]
      })
    ],
    name: 'co-contractors1-new',
    maxNumberOfMembers: 3
  })
);
