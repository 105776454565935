/* eslint-disable no-magic-numbers */
import React, { useEffect, useState } from 'react';
import PageContent from 'ui/templates/PageContent/PageContent';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import Table from 'ui/organisms/Table/Table';
import TableHeaderRow from 'ui/molecules/TableHeaderRow/TableHeaderRow';
import TableHeader from 'ui/atoms/TableHeader/TableHeader';
import Drawer from 'components/Drawer/Drawer';
import useDrawer from 'hooks/useDrawer/useDrawer';
import NoCoContractorGroups from 'ui/molecules/NoCoContractorGroups/NoCoContractorGroups';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import useCoContractors from 'hooks/useCoContractors/useCoContractors';
import ArrayUtil from 'utils/Array/Array.util';
import TableRow from 'ui/molecules/TableRow/TableRow';
import TableCell from 'ui/atoms/TableCell/TableCell';
import TableCellIcon from 'ui/atoms/TableCellIcon/TableCellIcon';
import NoCoContractorsWithButton from 'ui/molecules/NoCoContractorsWithButton/NoCoContractorsWithButton';
import useSortingCoContractorsNewTable from 'hooks/useSorting/useSortingCoContractorsTable/useSortingCoContractorsTable';
import TableHeaderButton from 'ui/atoms/TableHeaderButton/TableHeaderButton';
import Modal from 'components/Modal/Modal';
import {
  GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant_members_mobileDevices,
  GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant as VisitorGroup
} from 'graphql/generated/GetVisitorGroupsForTenantWithMembers';
import useAuth from 'hooks/usaAuth/useAuth';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import TableCellEmpty from 'ui/atoms/TableCellEmpty/TableCellEmpty';
import { StickyToTop } from 'ui/templates/StickyToTop/StickyToTop';
import styled from 'styled-components';
import SearchCoContractors from 'ui/organisms/SearchCoContractors/SearchCoContractors';
import { usersTabs } from '../usersTabsConst';

dayjs.extend(isBetween);

export const headerGroups = ['External groups'] as const;
export const headersUsers = ['External users', 'ATLAS Access app', 'Expiration date'] as const;

const SearchWrapper = styled.div`
  margin: 1rem 0 2rem 0;
  width: 14.875rem;
`;

const CoContractorsPage = (): JSX.Element => {
  const { showDrawer } = useDrawer();
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const {
    coContractorsGroups,
    loading: coContractorsGroupsLoading,
    deleteVisitorGroupByIdMutationLoading
  } = useCoContractors({
    handleFetchError
  });
  const [selectedGroupId, setSelectedGroupId] = useState<string>();
  const skeletonArray = ArrayUtil.SkeletonArray();
  const { handleSortingGroupsIcon, handleSortingGroupsOnClick, handleSortingUsersIcon, handleSortingUsersOnClick } =
    useSortingCoContractorsNewTable();
  const [filterStr, setFilterStr] = useState('');
  const [filterGroupId, setFilterGroupId] = useState<string | undefined>();
  const [filterMemberId, setFilterMemberId] = useState<string | undefined>();
  const [isSearchMenuOpen, setIsSearchMenuOpen] = useState(false);
  const { isMobileDeviceRegisteredWithAccess } = useAuth();

  const getGroupById = (groupId: string) => coContractorsGroups.find((group) => group.id === groupId);

  const getGroupByMemberId = (memberId: string) =>
    coContractorsGroups.find((group) => group.members.some((member) => member.id === memberId));

  const getMemberById = (memberId: string) => {
    const groupWithMember = coContractorsGroups.find((group) => group.members.some((member) => member.id === memberId));
    return groupWithMember?.members.find((member) => member.id === memberId);
  };

  const getFilteredGroups = () => {
    if (filterGroupId !== undefined) {
      const filteredGroup = getGroupById(filterGroupId);
      return filteredGroup ? [filteredGroup] : undefined;
    }
    if (filterMemberId !== undefined) {
      const filteredGroup = getGroupByMemberId(filterMemberId);
      return filteredGroup ? [filteredGroup] : undefined;
    }
    return coContractorsGroups;
  };

  const getFirstSectionItems = () =>
    coContractorsGroups
      .filter((element) => [element].some((key) => key.name.toLowerCase().includes(filterStr.toLowerCase())))
      .map((group) => ({ id: group.id, text: group.name }));

  const getSecondSectionItems = () =>
    coContractorsGroups.flatMap((group) =>
      group.members
        .filter((element) =>
          [element.name, element.email].some((key) => key && key.toLowerCase().includes(filterStr.toLowerCase()))
        )
        .flatMap((member) => ({
          id: member.id,
          text: member.name,
          email: member.email,
          additionalInfoText: group.name
        }))
    );

  const handleFirstSectionRowOnClick = (id: string) => {
    setIsSearchMenuOpen(false);
    setFilterGroupId(id);
    setFilterMemberId(undefined);
    setSelectedGroupId(id);
    const filteredGroups = getGroupById(id);
    if (filteredGroups) setFilterStr(filteredGroups.name);
  };

  const handleSecondSectionRowOnClick = (id: string) => {
    setIsSearchMenuOpen(false);
    setFilterGroupId(undefined);
    setFilterMemberId(id);

    const filteredGroups = getGroupByMemberId(id);
    if (filteredGroups) setSelectedGroupId(filteredGroups.id);
    const filteredMember = getMemberById(id);
    if (filteredMember) setFilterStr(filteredMember.name);
  };

  const handleClearButtonOnClick = () => {
    setIsSearchMenuOpen(false);
    setFilterGroupId(undefined);
    setFilterMemberId(undefined);
    setFilterStr('');
  };

  const handleSetFilterStrOnChange = (text: string) => {
    if (text === '') handleClearButtonOnClick();
    setIsSearchMenuOpen(Boolean(text));
    setFilterStr(text);
  };

  useEffect(() => {
    if (selectedGroupId === undefined && coContractorsGroups.length > 0 && coContractorsGroups[0].id)
      setSelectedGroupId(coContractorsGroups[0].id);
  }, [coContractorsGroups, coContractorsGroupsLoading, selectedGroupId]);

  const handleAddCoContractorGroupClick = () => {
    showDrawer({
      type: 'addCoContractorGroup'
    });
  };

  const handleAddCoContractorClick = (visitorGroup: VisitorGroup) => {
    if (visitorGroup)
      showDrawer({
        type: 'addCoContractor',
        contentValue: { visitorGroupExternalRef: visitorGroup.externalRef }
      });
  };

  const handleEditCoContractorClick = (
    name: string,
    email: string | null,
    mobileDevices: GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant_members_mobileDevices[],
    id: string,
    group: string,
    isEditable: boolean,
    expirationDate: string | null
  ) => {
    showDrawer({
      type: 'editCoContractor',
      contentValue: {
        name,
        email,
        mobileDevices,
        id,
        group,
        isEditable,
        expirationDate
      }
    });
  };

  const handleEditCoContractorGroupClick = (name: string, visitorGroupId: string) => {
    showDrawer({
      type: 'editCoContractorGroup',
      contentValue: {
        name,
        visitorGroupId,
        maxNumberOfMembers: null
      }
    });
  };

  const getSelectedGroup = () => {
    if (filterMemberId !== undefined) {
      const groupsWithFilterMember = getGroupByMemberId(filterMemberId);
      if (groupsWithFilterMember && selectedGroupId) {
        const selectedGroup = getGroupById(selectedGroupId);
        if (selectedGroup) {
          return { ...selectedGroup, members: selectedGroup.members.filter((member) => member.id === filterMemberId) };
        }
      }
    }
    return selectedGroupId ? getGroupById(selectedGroupId) : undefined;
  };

  const isSelectedGroupHaveMembers = () => {
    const selectedGroup = getSelectedGroup();
    return selectedGroup ? selectedGroup.members.length > 0 : undefined;
  };

  const isSelectedGroupNotHaveMembers = () => {
    const selectedGroup = getSelectedGroup();
    return selectedGroup ? selectedGroup.members.length === 0 : undefined;
  };

  const isTableVisible = () =>
    coContractorsGroupsLoading || (!coContractorsGroupsLoading && coContractorsGroups.length > 0);
  const isTableLoadingVisible = () => coContractorsGroupsLoading;
  const isTableGroupsRowsWithDataVisible = () => !coContractorsGroupsLoading && coContractorsGroups.length > 0;
  const isTableMembersRowsWithDataVisible = () =>
    !coContractorsGroupsLoading &&
    !deleteVisitorGroupByIdMutationLoading &&
    coContractorsGroups.length > 0 &&
    isSelectedGroupHaveMembers();
  const isTableMembersNoMembersVisible = () =>
    !coContractorsGroupsLoading &&
    !deleteVisitorGroupByIdMutationLoading &&
    coContractorsGroups.length > 0 &&
    isSelectedGroupNotHaveMembers();

  const isGroupSortingAvailable = filterGroupId === undefined && filterMemberId === undefined;
  const isMemberSortingAvailable = () => {
    if (filterGroupId) {
      const filteredGroup = getGroupById(filterGroupId);
      return Boolean(filteredGroup && filteredGroup.members.length > 1);
    }
    if (filterMemberId) return false;
    return true;
  };

  useEffect(() => {
    if (deleteVisitorGroupByIdMutationLoading) setSelectedGroupId(undefined);
  }, [deleteVisitorGroupByIdMutationLoading]);

  const handleColorDate = (expirationDateTo: string | null) => {
    if (expirationDateTo) {
      if (dayjs(expirationDateTo).isBefore(dayjs().add(24, 'h'), 'h')) return 'error';
      if (dayjs(expirationDateTo).isBetween(dayjs(), dayjs().add(1, 'week'), 'day')) return 'warning';
    }
    return 'lTextHigh';
  };

  return (
    <PageContent
      title="ATLAS"
      titleId="location"
      tabs={usersTabs}
      buttonText="Add group"
      buttonId="add-external-group-button"
      buttonOnClick={handleAddCoContractorGroupClick}
    >
      <StickyToTop>
        <ComponentWrapper alignItems="center" justifyContent="end" gap="0.5rem">
          <SearchWrapper>
            <SearchCoContractors
              isOpen={isSearchMenuOpen}
              filterStr={filterStr}
              setFilterStr={handleSetFilterStrOnChange}
              firstSectionItems={getFirstSectionItems()}
              secondSectionItems={getSecondSectionItems()}
              firstHeader="External groups"
              secondHeader="External users"
              handleFirstSectionRowOnClick={handleFirstSectionRowOnClick}
              handleSecondSectionRowOnClick={handleSecondSectionRowOnClick}
              handleClearButtonOnClick={handleClearButtonOnClick}
            />
          </SearchWrapper>
        </ComponentWrapper>
      </StickyToTop>
      <ComponentWrapper
        height="calc(100vh - 20rem)"
        flex="1 1 auto"
        margin="1rem"
        justifyContent="center"
        alignItems="center"
      >
        {isTableVisible() && (
          <>
            <ComponentWrapper height="calc(100vh - 20rem)" flex="0 0 30%">
              <Table
                header={
                  <TableHeaderRow>
                    <TableHeader
                      headerText={headerGroups[0]}
                      id={`header-${headerGroups[0].replace(' ', '-').toLocaleLowerCase()}`}
                      flex="0 0 100%"
                      iconSorting={isGroupSortingAvailable ? handleSortingGroupsIcon(headerGroups[0]) : undefined}
                      onClick={isGroupSortingAvailable ? () => handleSortingGroupsOnClick(headerGroups[0]) : undefined}
                    />
                  </TableHeaderRow>
                }
              >
                {isTableLoadingVisible() &&
                  skeletonArray.map((_, index) => (
                    <TableRow id={`row-skeleton-${index}`} key={`skeletonTableLeftColumnRow-${_.id}`}>
                      <TableCell isLoading firstLineText="" flex="0 0 100%" />
                    </TableRow>
                  ))}
                {isTableGroupsRowsWithDataVisible() &&
                  getFilteredGroups()?.map((group, id) => (
                    <TableRow
                      onClick={() => setSelectedGroupId(group.id)}
                      id={`row-group-${id}`}
                      hoverEffect
                      hoverAfterIconEffect
                      selected={group.id === selectedGroupId}
                      key={group.id}
                      beforeContentIconProps={{
                        name: 'CoContractor',
                        width: 24,
                        height: 24,
                        viewBox: '0 0 32 32',
                        color: 'transparent',
                        stroke: 'lTextHigh'
                      }}
                      afterContentIconProps={{
                        name: 'EditPen',
                        id: `row-group-${id}-icon-edit-pen`,
                        width: 24,
                        height: 24,
                        onClick: () => handleEditCoContractorGroupClick(group.name, group.id)
                      }}
                    >
                      <TableCell
                        firstLineText={group.name}
                        firstLineId={`group-row-${id}-name`}
                        firstLineColor={group.id === selectedGroupId ? 'primary' : 'lTextHigh'}
                        flex="0 0 100%"
                        width="15rem"
                      />
                    </TableRow>
                  ))}
              </Table>
            </ComponentWrapper>
            <ComponentWrapper height="calc(100vh - 20rem)" flex="0 0 70%">
              <Table
                header={
                  <TableHeaderRow>
                    <TableHeader
                      headerText={headersUsers[0]}
                      id={`header-${headersUsers[0].replace(' ', '-').toLocaleLowerCase()}`}
                      flex="0 0 20%"
                      iconSorting={isMemberSortingAvailable() ? handleSortingUsersIcon(headersUsers[0]) : undefined}
                      onClick={
                        isMemberSortingAvailable() ? () => handleSortingUsersOnClick(headersUsers[0]) : undefined
                      }
                    />
                    <TableHeader
                      headerText={headersUsers[1]}
                      id={`header-${headersUsers[1].replace(' ', '-').toLocaleLowerCase()}`}
                      flex="0 0 20%"
                      iconSorting={isMemberSortingAvailable() ? handleSortingUsersIcon(headersUsers[1]) : undefined}
                      onClick={
                        isMemberSortingAvailable() ? () => handleSortingUsersOnClick(headersUsers[1]) : undefined
                      }
                    />
                    <TableHeader
                      headerText={headersUsers[2]}
                      id={`header-${headersUsers[2].replace(' ', '-').toLocaleLowerCase()}`}
                      flex="0 0 20%"
                      iconSorting={isMemberSortingAvailable() ? handleSortingUsersIcon(headersUsers[2]) : undefined}
                      onClick={
                        isMemberSortingAvailable() ? () => handleSortingUsersOnClick(headersUsers[2]) : undefined
                      }
                    />
                    <TableHeaderButton
                      buttonProps={{
                        children: '+ Add',
                        onClick: () => handleAddCoContractorClick(getSelectedGroup()!)
                      }}
                      id="add-external-group-member-button"
                      flex="0 0 20%"
                    />
                  </TableHeaderRow>
                }
              >
                {isTableLoadingVisible() &&
                  skeletonArray.map((_, index) => (
                    <TableRow id={`row-skeleton-${index}`} key={`skeletonTableRightColumnRow-${_.id}`}>
                      <TableCell isLoading firstLineText="" flex="0 0 20%" />
                      <TableCell isLoading firstLineText="" flex="0 0 20%" />
                      <TableCell isLoading firstLineText="" flex="0 0 40%" />
                      <TableCell isLoading firstLineText="" flex="0 0 00%" />
                    </TableRow>
                  ))}
                {isTableMembersRowsWithDataVisible() &&
                  getSelectedGroup()?.members.map((member, id) => (
                    <TableRow
                      id={`row-external-group-member-${id}`}
                      hoverEffect
                      onClick={() =>
                        handleEditCoContractorClick(
                          member.name,
                          member.email,
                          member.mobileDevices,
                          member.id,
                          selectedGroupId!,
                          member.isEditable!,
                          member.expirationDate
                        )
                      }
                      key={`TableRowMember-${member.id}`}
                    >
                      <TableCell
                        firstLineText={member.name}
                        firstLineId={`row-external-group-member-${id}-name`}
                        secondLineText={member.email || undefined}
                        secondLineId={`row-external-group-member-${id}-email`}
                        flex="0 0 20%"
                      />
                      <TableCellIcon
                        iconProps={{
                          name: isMobileDeviceRegisteredWithAccess(member.mobileDevices)
                            ? 'StatusConnected'
                            : 'StatusDisconnected',
                          color: isMobileDeviceRegisteredWithAccess(member.mobileDevices) ? 'success' : 'error',
                          width: 92,
                          height: 12,
                          id: `row-external-group-member-${id}-status`
                        }}
                        flex="0 0 20%"
                      />
                      <TableCell
                        firstLineText={
                          member.expirationDate
                            ? dayjs(member.expirationDate).format('DD-MM-YYYY HH:mm')
                            : 'Never expire'
                        }
                        firstLineColor={handleColorDate(member.expirationDate)}
                        firstLineId={`row-external-group-member-${id}-expirationDate`}
                        flex="0 0 20%"
                      />
                      <TableCellEmpty flex="0 0 20%" />
                    </TableRow>
                  ))}
                {isTableMembersNoMembersVisible() && (
                  <ComponentWrapper justifyContent="center" alignItems="center" height="calc(100vh - 23rem)">
                    <NoCoContractorsWithButton
                      handleOnButtonClick={() => handleAddCoContractorClick(getSelectedGroup()!)}
                    />
                  </ComponentWrapper>
                )}
              </Table>
            </ComponentWrapper>
          </>
        )}
        {!coContractorsGroupsLoading && coContractorsGroups.length === 0 && (
          <NoCoContractorGroups handleOnButtonClick={handleAddCoContractorGroupClick} />
        )}
      </ComponentWrapper>
      <Drawer />
      <Modal />
    </PageContent>
  );
};
export default CoContractorsPage;
