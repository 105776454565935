import React from 'react';
import styled from 'styled-components';
import Typography from 'ui/atoms/Typography/Typography';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import Search from '../../molecules/Search/Search';

interface ItemProps {
  id: string;
  text: string;
  email?: string | null;
  additionalInfoText?: string;
}

export interface Props {
  isOpen: boolean;
  filterStr: string;
  setFilterStr: (text: string) => void;
  firstHeader: string;
  firstSectionItems: ItemProps[];
  secondHeader: string;
  secondSectionItems: ItemProps[];
  handleFirstSectionRowOnClick: (id: string) => void;
  handleSecondSectionRowOnClick: (id: string) => void;
  handleClearButtonOnClick: () => void;
}

const StyledOpenMenu = styled.div`
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.b3};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: ${({ theme }) => theme.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.colors.b4};
  position: absolute;
  z-index: 100;
  visibility: hidden;
  overflow-y: auto;
  max-height: 28rem;
  min-width: 20rem;
`;

const StyledContainer = styled.div<Pick<Props, 'isOpen'>>`
  position: relative;
  display: flex;

  & ${StyledOpenMenu} {
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  }
`;

const SearchCoContractors: React.FC<Props> = ({
  isOpen,
  setFilterStr,
  filterStr,
  firstHeader,
  firstSectionItems,
  secondHeader,
  secondSectionItems,
  handleFirstSectionRowOnClick,
  handleSecondSectionRowOnClick,
  handleClearButtonOnClick
}) => {
  const getTextBeforeHighlight = (text: string) =>
    text.slice(0, text.toLocaleLowerCase().indexOf(filterStr.toLocaleLowerCase()));
  const getTextAfterHighlight = (text: string) =>
    text.slice(text.toLocaleLowerCase().indexOf(filterStr.toLocaleLowerCase()) + filterStr.length);

  return (
    <StyledContainer isOpen={isOpen}>
      <StyledOpenMenu>
        <ComponentWrapper flexDirection="column" margin="0.5rem 1rem" gap="0.5rem">
          {firstSectionItems.length > 0 && (
            <Typography variant="body3" color="lTextLow" whiteSpace="nowrap">
              {firstHeader}
            </Typography>
          )}
          {firstSectionItems.map((item, index) => (
            <ComponentWrapper
              flexDirection="row"
              onClick={() => handleFirstSectionRowOnClick(item.id)}
              cursor="pointer"
              id={`external-groups-${index}`}
            >
              <Typography variant="body3" color="lTextHigh" whiteSpace="nowrap">
                {getTextBeforeHighlight(item.text)}
              </Typography>
              <Typography variant="body3" color="primary" whiteSpace="nowrap">
                {filterStr}
              </Typography>
              <Typography variant="body3" color="lTextHigh" whiteSpace="nowrap">
                {getTextAfterHighlight(item.text)}
              </Typography>
            </ComponentWrapper>
          ))}
          {secondSectionItems.length > 0 && (
            <Typography variant="body3" color="lTextLow" whiteSpace="nowrap">
              {secondHeader}
            </Typography>
          )}
          {secondSectionItems.length > 0 &&
            secondSectionItems.map((item, index) => (
              <ComponentWrapper
                flexDirection="column"
                onClick={() => handleSecondSectionRowOnClick(item.id)}
                cursor="pointer"
                id={`external-users-${index}`}
              >
                {item.text?.includes(filterStr) ? (
                  <ComponentWrapper flexDirection="row">
                    <Typography variant="body3" color="lTextHigh" whiteSpace="nowrap">
                      {getTextBeforeHighlight(item.text)}
                    </Typography>
                    <Typography variant="body3" color="primary" whiteSpace="nowrap">
                      {filterStr}
                    </Typography>
                    <Typography variant="body3" color="lTextHigh" whiteSpace="nowrap">
                      {getTextAfterHighlight(item.text)}
                    </Typography>
                  </ComponentWrapper>
                ) : (
                  <ComponentWrapper flexDirection="row">
                    <Typography variant="body3" color="lTextHigh" whiteSpace="nowrap">
                      {item.text}
                    </Typography>
                  </ComponentWrapper>
                )}
                {item.email?.includes(filterStr) ? (
                  <ComponentWrapper flexDirection="row">
                    <Typography variant="body4" color="lTextHigh" whiteSpace="nowrap">
                      {getTextBeforeHighlight(item.email)}
                    </Typography>
                    <Typography variant="body4" color="primary" whiteSpace="nowrap">
                      {filterStr}
                    </Typography>
                    <Typography variant="body4" color="lTextHigh" whiteSpace="nowrap">
                      {getTextAfterHighlight(item.email)}
                    </Typography>
                  </ComponentWrapper>
                ) : (
                  <ComponentWrapper flexDirection="row">
                    <Typography variant="body4" color="lTextHigh" whiteSpace="nowrap">
                      {item.email}
                    </Typography>
                  </ComponentWrapper>
                )}
                <Typography variant="body4" color="lTextMedium" whiteSpace="nowrap">
                  {item.additionalInfoText}
                </Typography>
              </ComponentWrapper>
            ))}
          {firstSectionItems.length === 0 && secondSectionItems.length === 0 && (
            <Typography variant="body3" color="lTextMedium" whiteSpace="nowrap">
              No results
            </Typography>
          )}
        </ComponentWrapper>
      </StyledOpenMenu>
      <Search
        onChange={(event) => setFilterStr(event.target.value)}
        value={filterStr}
        clearButtonOnClick={handleClearButtonOnClick}
        variant="big"
      />
    </StyledContainer>
  );
};

export default SearchCoContractors;
